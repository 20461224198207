import { useCallback, useState } from 'react';
import { calculateResult, isUselessTimer } from './util/CalculateResult';
import { gql, useMutation } from '@apollo/client';

import ActivitySelector from './ActivitySelector.react';
import Timer from './Timer.react';
import { ToastContainer, toast } from 'react-toastify';
import TagContainer from './TagContainer.react';

import {
  getAppSettings,
  saveAppSettings,
  SettingOptions,
} from '../db/SettingsStorage';

import 'react-toastify/dist/ReactToastify.css';
import { prettyPrintTime } from './util/HelperFunctions';
import TargetSelector, { ITarget } from './TargetSelector';
import { CharacterSheet } from './util/types/CharacterSheetType';
import { ITag } from './util/types/TimerType';
import { ITimer } from './TimerLog.react';

const ADD_TIMER_TO_USER = gql`
  mutation SaveTimer($data: SaveTimerInput!) {
    saveTimer(data: $data) {
      id
      account {
        username
      }
      tag {
        value
      }
    }
  }
`;

type ActivityPageProps = {
  accountId: string;
  characterSheet: CharacterSheet;
  addTimerToArray: (newTimer: ITimer) => void;
  storeSheet: (newSheet: CharacterSheet) => void;
  tags: Array<ITag>;
};

function ActivityPage({
  accountId,
  characterSheet,
  addTimerToArray,
  storeSheet,
  tags,
}: ActivityPageProps) {
  const [selectedTarget, setSelectedTarget] = useState<ITarget | undefined>();
  const [settings, setSettings] = useState(getAppSettings());
  const [selectedTag, setSelectedTag] = useState({ id: '', value: '' });
  const [resultString, setResultString] = useState<null | string>(null);

  const [saveTimer] = useMutation(ADD_TIMER_TO_USER);

  const updateSettings = useCallback(
    function (setting: SettingOptions, newValue: any) {
      const newSettings = { ...settings, [setting]: newValue };
      setSettings(newSettings);
      saveAppSettings(newSettings);
      toast('Saved settings');
    },
    [settings],
  );

  const onCompleted = async function (
    startTime: Date,
    timerLength: number,
  ): Promise<void> {
    await saveNewTimer(startTime, timerLength);
  };

  const saveNewTimer = async function (
    startTime: Date,
    timerLength: number,
  ): Promise<void> {
    const result = calculateResult(
      timerLength,
      settings.selectedActivity,
      characterSheet,
      selectedTarget?.id ?? '0',
    );
    storeSheet(result.sheet);
    setResultString(result.resultString);
    const encodedSheet = window.btoa(JSON.stringify(result.sheet));

    await saveTimer({
      variables: {
        data: {
          completed: true,
          durationInSeconds: timerLength,
          startTime: startTime,
          task: settings.selectedActivity,
          tagId: selectedTag.id,
          userId: accountId,
          encodedCharacterSheet: encodedSheet,
        },
      },
      onCompleted: (res) => {
        toast.success(
          `Successfully saved ${prettyPrintTime(timerLength)} long timer of "${
            settings.selectedTag
          }"`,
        );
        const newTimer = {
          id: res.saveTimer.id,
          startTime: startTime.toString(),
          durationInSeconds: timerLength,
          tag: {
            id: selectedTag.id,
            value: selectedTag.value,
          },
        };
        addTimerToArray(newTimer);
      },
      onError: () => {
        toast.error('Encountered an error saving your timer');
      },
    });
  };

  return (
    <div>
      <Timer
        timerLength={settings.timerLength}
        setTimerLength={(newLength) =>
          updateSettings(SettingOptions.timerLength, newLength)
        }
        isContinueModeEnabled={settings.isContinueModeEnabled ?? true}
        onCompleted={(startTime: Date, timerLength: number) => {
          onCompleted(startTime, timerLength);
        }}
      />
      <TagContainer
        accountId={accountId}
        settings={settings}
        tagsFromParent={tags}
        updateSettings={updateSettings}
        updateSelectedTag={setSelectedTag}
      />
      <ActivitySelector
        selectedActivity={settings.selectedActivity}
        setSelectedActivity={(newActivity) => {
          setSelectedTarget(undefined);
          updateSettings(SettingOptions.selectedActivity, newActivity);
        }}
      />
      <TargetSelector
        selectedActivity={settings.selectedActivity}
        selectedTarget={selectedTarget}
        setSelectedTarget={(newTarget) => {
          setSelectedTarget(newTarget);
        }}
      />
      <div>
        <label htmlFor="continueModeCheckbox">Continue mode enabled</label>
        <input
          name="continueModeCheckbox"
          type="checkbox"
          checked={settings.isContinueModeEnabled ?? true}
          onChange={() => {
            updateSettings(
              SettingOptions.isContinueModeEnabled,
              !settings.isContinueModeEnabled,
            );
          }}
        />
        <div>
          {isUselessTimer(
            settings.timerLength,
            settings.selectedActivity,
            characterSheet,
            selectedTarget?.id ?? '0',
          )}
        </div>
        {resultString ? (
          <>
            <div>{resultString}</div>
            <button onClick={() => setResultString(null)}>Clear Notice</button>
          </>
        ) : null}
      </div>
      <ToastContainer theme="dark" autoClose={2500} />
    </div>
  );
}

export default ActivityPage;

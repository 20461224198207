import { ForgingSkilling } from '../skills/Forging';
import { RockFarmingSkilling } from '../skills/RockFarming';
import { SmithingSkilling } from '../skills/Smithing';
import { CharacterSheet } from './types/CharacterSheetType';

export function calculateResult(
  timerLength: number,
  activity: string,
  sheet: CharacterSheet,
  targetId: string,
): { sheet: CharacterSheet; resultString: string } {
  switch (activity) {
    case 'rockFarming':
      return new RockFarmingSkilling().calculateSession(
        timerLength,
        sheet,
        targetId,
      );
    case 'forging':
      return new ForgingSkilling().calculateSession(
        timerLength,
        sheet,
        targetId,
      );
    case 'smithing':
      return new SmithingSkilling().calculateSession(
        timerLength,
        sheet,
        targetId,
      );
  }

  return {
    sheet,
    resultString: 'Encountered an error calculating your activity',
  };
}

export function isUselessTimer(
  timerLength: number,
  activity: string,
  sheet: CharacterSheet,
  targetId: string,
): string {
  switch (activity) {
    case 'rockFarming':
      return new RockFarmingSkilling().isUselessTimer(
        timerLength,
        sheet,
        targetId,
      );
    case 'forging':
      return new ForgingSkilling().isUselessTimer(timerLength, sheet, targetId);
    case 'smithing':
      return new SmithingSkilling().isUselessTimer(
        timerLength,
        sheet,
        targetId,
      );
  }

  return '';
}

export enum BaseStat {
  'str' = 'str',
  'dex' = 'dex',
  'int' = 'int',
  'luk' = 'luk',
}

export enum GearStat {
  'miningStrength' = 'miningStrength',
}

export type Stat = GearStat | BaseStat;

export const prettyPrintStat = (stat: Stat): string => {
  switch (stat) {
    case 'miningStrength':
      return 'Mining strength';
    case 'str':
      return 'Strength';
    case 'dex':
      return 'Dexterity';
    case 'int':
      return 'Intelligence';
    case 'luk':
      return 'Luck';
    default:
      return '';
  }
};

import { gql } from '@apollo/client';

export const GET_ACCOUNT_DATA = gql`
  query GetAccountData($username: String, $accountId: String) {
    getAccountData(username: $username, accountId: $accountId) {
      encodedCharacterSheet
      username
      id
      tags {
        id
        value
        deleted
      }
      timers {
        id
        startTime
        durationInSeconds
        tag {
          id
          value
        }
      }
    }
  }
`;

export const GET_TIMERS = gql`
  query GetAllTimers($accountId: ID!) {
    getTimersForUser(accountId: $accountId) {
      id
      startTime
      durationInSeconds
      tag {
        id
        value
      }
    }
  }
`;

import { EquipSlot } from '../components/util/types/EquipSlot';
import { SmithingCategories } from '../components/util/types/SmithingCategories';
import { Stat } from '../components/util/types/Stats';

export class ItemComponent {
  componentName: string;
  constructor(name: string) {
    this.componentName = name;
  }
}

export class ItemComponent_Stackable extends ItemComponent {
  stackSize: number;
  quantity: number;
  constructor(stackSize = 200, quantity = 0) {
    super('stackable');
    this.stackSize = stackSize;
    this.quantity = quantity;
  }
}

export class ItemComponent_RockFarmingTarget extends ItemComponent {
  minimumSkill: number;
  expValue: number;
  tickSpeed: number;
  constructor(minimumSkill: number, expValue: number, tickSpeed: number) {
    super('rockFarmingTarget');
    this.minimumSkill = minimumSkill;
    this.expValue = expValue;
    this.tickSpeed = tickSpeed;
  }
}

export class ItemComponent_Forgeable extends ItemComponent {
  minimumSkill: number;
  expValue: number;
  tickSpeed: number;
  barTarget: string;
  constructor(
    minimumSkill: number,
    expValue: number,
    tickSpeed: number,
    barTarget: string,
  ) {
    super('forgeable');
    this.minimumSkill = minimumSkill;
    this.expValue = expValue;
    this.tickSpeed = tickSpeed;
    this.barTarget = barTarget;
  }
}

export class ItemComponent_Smithable extends ItemComponent {
  minimumSkill: number;
  expValue: number;
  tickSpeed: number;
  // materials is a dictionary where the key is the item ID and the value
  // is the quantity required.
  materials: Array<ICraftingComponent>;
  category: SmithingCategories;

  constructor(
    minimumSkill: number,
    expValue: number,
    tickSpeed: number,
    materials: Array<ICraftingComponent>,
    category: SmithingCategories,
  ) {
    super('smithable');
    this.minimumSkill = minimumSkill;
    this.expValue = expValue;
    this.tickSpeed = tickSpeed;
    this.materials = materials;
    this.category = category;
  }
}

export class ItemComponent_Equipable extends ItemComponent {
  equipSlot: EquipSlot;
  statsGranted: Record<Stat, number>;

  constructor(equipSlot: EquipSlot, statsGranted: Record<Stat, number>) {
    super('equipable');
    this.equipSlot = equipSlot;
    this.statsGranted = statsGranted;
  }
}

export class ItemComponent_Enhanceable extends ItemComponent {
  statsPerLevel: Record<Stat, number>;
  constructor(statsPerLevel: Record<Stat, number>) {
    super('enhanceable');
    this.statsPerLevel = statsPerLevel;
  }
}

export interface IItemComponents {
  stackable?: ItemComponent_Stackable;
  rockFarmingTarget?: ItemComponent_RockFarmingTarget;
  forgeable?: ItemComponent_Forgeable;
  smithable?: ItemComponent_Smithable;
  equipable?: ItemComponent_Equipable;
  enhanceable?: ItemComponent_Enhanceable;
}

export interface IItem {
  id: string;
  name: string;
  components: IItemComponents;
}

export interface IInvItem {
  id: string;
  position: number;
  components: {
    stackable?: {
      quantity: number;
    };
    equipable?: {
      equipped: boolean;
      slot: EquipSlot;
      stats: Record<Stat, number>;
    };
    enhanceable?: {
      exp: number;
      level: number;
    };
  };
}

export interface ICraftingComponent {
  itemId: string;
  quantity: number;
}

const ItemTable: Array<IItem> = [
  {
    id: '0',
    name: 'Copper Ore',
    components: {
      stackable: new ItemComponent_Stackable(),
      rockFarmingTarget: new ItemComponent_RockFarmingTarget(0, 5, 1),
      forgeable: new ItemComponent_Forgeable(0, 5, 1, '2'),
    },
  },
  {
    id: '1',
    name: 'Tin Ore',
    components: {
      stackable: new ItemComponent_Stackable(),
      rockFarmingTarget: new ItemComponent_RockFarmingTarget(40, 15, 2),
      forgeable: new ItemComponent_Forgeable(0, 5, 1, '3'),
    },
  },
  {
    id: '2',
    name: 'Copper Bar',
    components: {
      stackable: new ItemComponent_Stackable(),
    },
  },
  {
    id: '3',
    name: 'Tin Bar',
    components: {
      stackable: new ItemComponent_Stackable(),
    },
  },
  {
    id: '4',
    name: 'Copper Pickaxe',
    components: {
      smithable: new ItemComponent_Smithable(
        0,
        20,
        5,
        [{ itemId: '2', quantity: 10 }],
        SmithingCategories.tierone,
      ),
      equipable: new ItemComponent_Equipable(EquipSlot.PICKAXE, {
        miningStrength: 5,
        str: 1,
        dex: 1,
        int: 0,
        luk: 1,
      }),
      enhanceable: new ItemComponent_Enhanceable({
        miningStrength: 1,
        str: 1,
        dex: 0,
        int: 0,
        luk: 0,
      }),
    },
  },
  {
    id: '5',
    name: 'Tin Pickaxe',
    components: {
      smithable: new ItemComponent_Smithable(
        0,
        20,
        5,
        [{ itemId: '3', quantity: 20 }],
        SmithingCategories.tierone,
      ),
      equipable: new ItemComponent_Equipable(EquipSlot.PICKAXE, {
        miningStrength: 15,
        str: 3,
        dex: 2,
        int: 0,
        luk: 2,
      }),
      enhanceable: new ItemComponent_Enhanceable({
        miningStrength: 1.5,
        str: 1.5,
        dex: 0,
        int: 0,
        luk: 0,
      }),
    },
  },
];

export default ItemTable;

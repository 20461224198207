import { useEffect, useState } from 'react';
import TimerDisplay from './TimerDisplay.react';
import TimerLengthSelector from './TimerLengthSelector.react';

import { useStopwatch, useTimer } from 'react-timer-hook';
import { useSound } from 'use-sound';

const bell2 = require('../sfx/bell.wav');

type TimerProps = {
  isContinueModeEnabled: boolean;
  timerLength: number;
  setTimerLength: (newLenth: number) => void;
  onCompleted: (startTime: Date, timerLength: number) => void;
};

enum Mode {
  Finished,
  Stopwatch,
  Timer,
}

export default function Timer({
  isContinueModeEnabled,
  setTimerLength,
  timerLength,
  onCompleted,
}: TimerProps) {
  const [mode, setMode] = useState(Mode.Finished);
  const [startTime, setStartTime] = useState<Date | null>(null);

  const timer = new Date();
  timer.setSeconds(timer.getSeconds() + timerLength);

  const {
    seconds: tSeconds,
    minutes: tMinutes,
    isRunning: tIsRunning,
    start: tStart,
    pause: tPause,
    resume: tResume,
    restart: tRestart,
  } = useTimer({
    autoStart: false,
    expiryTimestamp: timer,
    onExpire: () => {
      finishTimer();
    },
  });

  const {
    seconds: sSeconds,
    minutes: sMinutes,
    isRunning: sIsRunning,
    start: sStart,
    pause: sPause,
    reset: sReset,
  } = useStopwatch({});

  const [play] = useSound(bell2);

  useEffect(() => {
    if (tIsRunning !== true && sIsRunning !== true) {
      if (process.env?.REACT_APP_ENV_VAR?.trim() === 'development') {
        document.title = '[TEST] ProductiQuest';
      } else {
        document.title = 'ProductiQuest';
      }
    }
  }, [tIsRunning, sIsRunning]);

  const finishTimer = function (): void {
    if (isContinueModeEnabled) {
      setMode(Mode.Stopwatch);
      sStart();
    } else {
      setMode(Mode.Finished);
      play();
      onCompleted(startTime as Date, timerLength);
      setStartTime(null);
      resetTimer();
    }
  };

  const resetTimer = function (newLength?: number): void {
    let target = new Date();
    target.setSeconds(target.getSeconds() + (newLength ?? timerLength));

    tRestart(target, false);
  };

  const finishBonusTime = function (): void {
    setMode(Mode.Finished);
    const totalSeconds = sMinutes * 60 + sSeconds + timerLength;
    resetTimer();
    sReset(undefined, false);
    if (startTime != null) {
      onCompleted(startTime, totalSeconds);
    }
  };

  const pauseTimer = function (): void {
    if (sIsRunning) {
      sPause();
    } else if (tIsRunning) {
      tPause();
    }

    if (process.env?.REACT_APP_ENV_VAR?.trim() === 'development') {
      document.title = '[TEST] ProductiQuest';
    } else {
      document.title = 'ProductiQuest';
    }
  };

  const cancelTimer = function (): void {
    resetTimer();
    setMode(Mode.Finished);
  };

  const startTimer = function (): void {
    mode === Mode.Finished ? tStart() : tResume();
    setMode(Mode.Timer);
    setStartTime(new Date());
  };

  const setNewLength = function (length: number): void {
    if (tIsRunning) tPause();
    let target = new Date();
    target.setSeconds(target.getSeconds() + length);
    setTimerLength(length);

    tRestart(target, false);
  };

  return (
    <div>
      {mode === Mode.Stopwatch ? (
        <span>
          Bonus Time
          <TimerDisplay
            isRunning={sIsRunning === true || tIsRunning === true}
            seconds={sSeconds}
            minutes={sMinutes}
          />
          <button
            onClick={() => {
              finishBonusTime();
            }}
          >
            Finish bonus time
          </button>
        </span>
      ) : (
        <TimerDisplay
          isRunning={sIsRunning === true || tIsRunning === true}
          seconds={tSeconds}
          minutes={tMinutes}
        />
      )}
      <div>
        <button disabled={tIsRunning || sIsRunning} onClick={startTimer}>
          {mode === Mode.Finished ? 'Start Timer' : 'Resume'}
        </button>
      </div>
      <button onClick={pauseTimer} disabled={!tIsRunning && !sIsRunning}>
        Pause
      </button>
      {mode === Mode.Timer && <button onClick={cancelTimer}>Cancel</button>}
      {mode === Mode.Finished && (
        <TimerLengthSelector
          setLength={(length: number) => {
            setNewLength(length);
          }}
        />
      )}
    </div>
  );
}

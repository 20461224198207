import {
  CharacterSheet,
  characterSheetDefault,
} from '../components/util/types/CharacterSheetType';
import ItemTable, { IInvItem } from './ItemTable';

export const CHARSHEET_VERSION = '0.1.1';
const localStoreKey = 'charSheet';

export const saveSheet = function (stats: CharacterSheet): void {
  localStorage.setItem(localStoreKey, JSON.stringify(stats));
};

export const getSheet = function (): CharacterSheet {
  const sheetRaw = localStorage.getItem(localStoreKey);
  if (sheetRaw != null) {
    let sheet = JSON.parse(sheetRaw);
    if (sheet.charSheetVersion !== CHARSHEET_VERSION) {
      sheet = updateInventoryComponents(sheet);
    }
    const typedSheet = {
      str: sheet.str,
      dex: sheet.dex,
      int: sheet.int,
      luk: sheet.luk,
      charSheetVersion: sheet.charsheet_version,
      herbalismExperience: sheet.herbalismExperience,
      herbalismLevel: sheet.herbalismLevel,
      forgingExperience: sheet.rockFarmingExperience,
      forgingLevel: sheet.rockFarmingLevel,
      rockFarmingExperience: sheet.rockFarmingExperience,
      rockFarmingLevel: sheet.rockFarmingLevel,
      smithingExperience: sheet.smithingExperience,
      smithingLevel: sheet.smithingLevel,
      inventory: {
        ...sheet.inventory,
        items: sheet.inventory.items,
      },
      gear: sheet.gear,
    } as CharacterSheet;
    return typedSheet;
  }

  return characterSheetDefault;
};

export const updateInventoryComponents = function (
  oldItems: Array<IInvItem>,
): Array<IInvItem> {
  const items = oldItems.map((item) => {
    const iItem = ItemTable[Number(item.id)];
    if (iItem.components.enhanceable && item.components.enhanceable == null) {
      item.components.enhanceable = {
        exp: 0,
        level: 0,
      };
    }
    if (iItem.components.stackable && item.components.stackable == null) {
      item.components.stackable = {
        quantity: 1,
      };
    }
    if (iItem.components.equipable) {
      item.components.equipable = {
        equipped: false,
        slot: iItem.components.equipable.equipSlot,
        stats: iItem.components.equipable.statsGranted,
      };
    }
    return item;
  });
  return items;
};

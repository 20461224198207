import { ActivityTypes } from './util/types/ActivityTypes';

type ActivityString = keyof typeof ActivityTypes;
type ActivitySelectorTypes = {
  selectedActivity: string;
  setSelectedActivity: (newVal: string) => void;
};

export default function ActivitySelector({
  selectedActivity,
  setSelectedActivity,
}: ActivitySelectorTypes) {
  return (
    <select
      onChange={(e) => {
        e.preventDefault();
        setSelectedActivity(e.target.value);
      }}
      value={selectedActivity}
    >
      {(Object.keys(ActivityTypes) as (keyof typeof ActivityTypes)[]).map(
        (activity: ActivityString) => {
          return (
            <option value={activity} key={activity}>
              {ActivityTypes[activity]}
            </option>
          );
        }
      )}
    </select>
  );
}

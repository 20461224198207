import ItemTable from '../../db/ItemTable';
import { CharacterSheet } from '../util/types/CharacterSheetType';
import { BaseSkilling } from './BaseSkilling';
import { SmithingSkilling } from './Smithing';

export class RockFarmingSkilling extends BaseSkilling {
  static base_tick_speed = 0.75;

  // completion count = e^(skillPower * 0.05) * (minutes / tick speed)
  // static calculateCompletionCount(
  //   timerLength: number,
  //   targetComponent: ItemComponent_RockFarmingTarget,
  // ): number {
  //   //TODO: fix this
  //   const miningPower = 1;

  //   const completionCount = Math.trunc(
  //     Math.exp((miningPower - targetComponent.minimumSkill) * 0.05) *
  //       (timerLength / 60 / targetComponent.tickSpeed),
  //   );
  //   return completionCount;
  // }

  // TODO: Genericize this?  The only thing skill specific is get power and that can be abstract
  calculateCompletionCount(
    timerLength: number,
    skillRequired: number,
    baseTickLength: number,
    sheet: CharacterSheet,
  ): number {
    const rockfarmingPower = this.getSkillPower(sheet);

    const completionCount = Math.trunc(
      Math.exp((rockfarmingPower - skillRequired) * 0.05) *
        (timerLength / 60 / baseTickLength),
    );
    return completionCount;
  }

  // TODO: break this out into 2 functions, getting equipped gear is generic.
  //       filtering for what is relevant isn't.
  public getSkillPower(sheet: CharacterSheet): number {
    const basePower = 1 + sheet.rockFarmingLevel * 0.2;
    const gearPower = sheet.inventory.items
      .filter((item) => item.components.equipable != null)
      .filter((item) => item.components.equipable?.equipped === true)
      .reduce((acc, item) => {
        const tableItem = ItemTable[Number(item.id)];
        let itemPower =
          tableItem?.components?.equipable?.statsGranted?.miningStrength ?? 0;
        if (item.components.enhanceable) {
          const enhanceablePower = SmithingSkilling.getEnhancementBonus(
            item.components.enhanceable.level,
          );
          if (enhanceablePower > 0) {
            itemPower += enhanceablePower;
          }
        }
        // TODO: Add multiplier for item level based on overcrafting
        return acc + itemPower;
      }, 0);
    return basePower + gearPower;
  }

  getFarmString(completionCount: number, itemTarget: string): string {
    const itemName = ItemTable[parseInt(itemTarget)].name;
    return `You farmed ${completionCount} ${itemName}`;
  }

  isUselessTimer(
    timerLength: number,
    sheet: CharacterSheet,
    itemTarget: string,
  ): string {
    if (timerLength === 0) {
      return '';
    }
    const item = ItemTable[parseInt(itemTarget)];
    if (item.components.rockFarmingTarget == null) {
      return RockFarmingSkilling.useless_timer_string;
    }
    const completionCount = this.calculateCompletionCount(
      timerLength,
      item.components.rockFarmingTarget.minimumSkill,
      item.components.rockFarmingTarget.tickSpeed,
      sheet,
    );
    return completionCount > 0 ? '' : RockFarmingSkilling.useless_timer_string;
  }

  getTarget(itemId: string) {
    try {
      const item = ItemTable[parseInt(itemId)];
      if (item.components.rockFarmingTarget == null) {
        throw new Error("Item isn't valid rock farming target");
      }

      return {
        baseTickLength: item.components.rockFarmingTarget.tickSpeed,
        expValue: item.components.rockFarmingTarget.expValue,
        skillRequired: item.components.rockFarmingTarget.minimumSkill,
      };
    } catch (error) {
      throw error;
    }
  }

  getSheetUpdates(
    oldSheet: CharacterSheet,
    targetId: string,
    completionCount: number,
  ) {
    const item = this.getTarget(targetId);
    const newLevels = this.calculateNewLevels(
      completionCount,
      oldSheet.rockFarmingExperience,
      oldSheet.rockFarmingLevel,
      item.expValue,
    );

    const newSheet = {
      ...oldSheet,
      rockFarmingLevel: newLevels.newLevel,
      rockFarmingExperience: newLevels.newExperience,
      inventory: {
        items: this.updateInventory(oldSheet, targetId, completionCount),
      },
    };

    return {
      newSheet: newSheet,
      gainedExp: newLevels.gainedExp,
      gainedLevels: newLevels.gainedLevels,
    };
  }
}

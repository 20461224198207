import React, { ReactNode, useContext } from 'react';
import ItemTable, { IInvItem } from '../db/ItemTable';
import { CharacterSheetContext } from './util/CharacterSheetContext';
import styled from 'styled-components';
import { EquipSlot } from './util/types/EquipSlot';
import { RockFarmingSkilling } from './skills/RockFarming';
import { ForgingSkilling } from './skills/Forging';
import { SmithingSkilling } from './skills/Smithing';
import { BaseStat } from './util/types/Stats';
import { SVGIconType } from './common/SVGIconType';
import Icon from './common/Icon.react';
import Popup from 'reactjs-popup';
import ItemCard from './ItemCard.react';

const SheetContainer = styled.div`
  display: flex;
  direction: row;
  column-gap: 24px;
`;

const SheetColumn = styled.span`
  width: 260px;
  height: 98vh;
  overflow-y: auto;
`;

export default function CharacterSheetPage() {
  const { characterSheet, setCharacterSheet } = useContext(
    CharacterSheetContext,
  );

  function toggleEquipped(toggleTarget: IInvItem): void {
    let newInv = [...characterSheet.inventory.items];
    let toggleTargetInInv = newInv.filter(
      (item) => item.position === toggleTarget.position,
    )[0];
    const TargetItem = ItemTable[Number(toggleTarget.id)];
    const slot = TargetItem.components.equipable?.equipSlot;
    if (slot == null) {
      return;
    }
    const previouslyEquippedItem =
      newInv.filter(
        (item, i) =>
          item.components.equipable?.slot === slot &&
          item.components.equipable.equipped === true &&
          item.position !== toggleTarget.position,
      )[0]?.position ?? null;
    if (
      newInv[previouslyEquippedItem]?.components?.equipable?.equipped != null
    ) {
      newInv[previouslyEquippedItem].components!.equipable!.equipped = false;
    }

    const isEquipped = toggleTargetInInv!.components!.equipable!.equipped;

    toggleTargetInInv!.components!.equipable!.equipped = !isEquipped;
    setCharacterSheet({
      ...characterSheet,
      inventory: { ...characterSheet.inventory, items: newInv },
    });
  }

  if (characterSheet == null) {
    return null;
  }

  const getEquipmentList = () => {
    const slots = Object.keys(EquipSlot);
    const equippedItems = characterSheet.inventory.items.filter(
      (item) => item?.components?.equipable?.equipped === true,
    );

    return (
      <>
        {slots.map((slot) => {
          const equippedItem = equippedItems.filter(
            (item) =>
              item.components.equipable?.slot ===
              EquipSlot[slot as keyof typeof EquipSlot],
          )[0];
          return (
            <div>
              {EquipSlot[slot as keyof typeof EquipSlot]}:{' '}
              {equippedItem != null
                ? EquipmentItemEntry(equippedItem)
                : 'Empty'}
            </div>
          );
        })}
      </>
    );
  };

  const getTotalStat = (stat: string) => {
    const convertedStat = stat as keyof typeof BaseStat;
    const baseStat = characterSheet[convertedStat];

    const gearTotal =
      characterSheet.inventory.items
        .filter((item) => item?.components?.equipable?.equipped === true)
        .reduce((total: number, item: IInvItem) => {
          if (item?.components?.equipable?.stats != null) {
            return (total +=
              item.components.equipable?.stats[convertedStat] ?? 0);
          } else {
            return total;
          }
        }, 0) ?? 0;

    return baseStat + gearTotal;
  };

  const deleteItem = (itemPosition: number) => {
    const newItems = characterSheet.inventory.items.filter(
      (item) => item.position !== itemPosition,
    );
    setCharacterSheet({
      ...characterSheet,
      inventory: {
        ...characterSheet.inventory,
        items: newItems,
      },
    });
  };

  const setNumberTo2DecimalPlaces = (val: number): number => {
    return +val.toFixed(2);
  };

  return (
    <SheetContainer>
      <SheetColumn>
        <h3> Stats </h3>
        <ul style={{ margin: 0 }}>
          <li>Strength: {getTotalStat('str') ?? 0} </li>
          <li>Dexterity: {getTotalStat('dex') ?? 0} </li>
          <li>Intelligence: {getTotalStat('int') ?? 0} </li>
          <li>Luck: {getTotalStat('luk') ?? 0} </li>
        </ul>
        <h4> Skills </h4>
        <ul style={{ margin: 0 }}>
          <li>
            Rock Farming Experience:{' '}
            {setNumberTo2DecimalPlaces(characterSheet.rockFarmingExperience)}
          </li>
          <li>Rock Farming Level: {characterSheet.rockFarmingLevel} </li>
          <li>
            Rock Farming Power:{' '}
            {setNumberTo2DecimalPlaces(
              new RockFarmingSkilling().getSkillPower(characterSheet),
            )}
          </li>
          <li>
            Forging Experience:{' '}
            {setNumberTo2DecimalPlaces(characterSheet.forgingExperience)}{' '}
          </li>
          <li>Forging Level: {characterSheet.forgingLevel} </li>
          <li>
            Forging Power:{' '}
            {setNumberTo2DecimalPlaces(
              new ForgingSkilling().getSkillPower(characterSheet),
            )}
          </li>
          <li>
            Smithing Experience:{' '}
            {setNumberTo2DecimalPlaces(characterSheet.smithingExperience)}{' '}
          </li>
          <li>Smithing Level: {characterSheet.smithingLevel} </li>
          <li>
            Smithing Power:{' '}
            {setNumberTo2DecimalPlaces(
              new SmithingSkilling().getSkillPower(characterSheet),
            )}
          </li>
        </ul>
      </SheetColumn>
      <SheetColumn>
        <h3> Equipment </h3>
        {getEquipmentList()}
      </SheetColumn>
      <SheetColumn>
        <h3>Inventory</h3>
        {characterSheet.inventory.items.filter(
          (item) =>
            item.components.equipable?.equipped === false ||
            item.components.equipable == null,
        ).length !== 0 ? (
          <ul>
            {characterSheet.inventory.items
              .filter(
                (item) =>
                  item?.components?.equipable == null ||
                  item?.components?.equipable?.equipped === false,
              )
              .map((item) => (
                <li
                  style={{ marginBottom: '4px' }}
                  key={`${item.id}+${item.position}`}
                >
                  {InvItemEntry(item, deleteItem)}
                </li>
              ))}
          </ul>
        ) : (
          <>No unequipped items in your inventory</>
        )}
      </SheetColumn>
    </SheetContainer>
  );

  function BaseItem(props: { item: IInvItem }) {
    return (
      <Popup
        trigger={() => <span>{ItemTable[parseInt(props.item.id)].name}</span>}
        on={['hover']}
      >
        <ItemCard invItem={props.item} />
      </Popup>
    );
  }
  function EquipmentItemEntry(item: IInvItem) {
    let extraContent: Array<ReactNode> = [];
    if (item.components.equipable != null) {
      extraContent.push(
        <span key={`${item.id}+equipable+${item.position}`}>
          {' '}
          <button onClick={() => toggleEquipped(item)}>
            {item.components.equipable.equipped ? 'Unequip' : 'Equip'}
          </button>
        </span>,
      );
    }
    return (
      <>
        <BaseItem item={item} />
        {extraContent}
      </>
    );
  }
  function InvItemEntry(
    item: IInvItem,
    deleteItem: (itemPosition: number) => void,
  ): ReactNode {
    let extraContent: Array<ReactNode> = [];
    if (item.components.stackable != null) {
      extraContent.push(
        <span key={`${item.id}+stackable+${item.position}`}>
          {' '}
          Quantity: {item.components.stackable.quantity}{' '}
        </span>,
      );
    }
    if (item.components.equipable != null) {
      extraContent.push(
        <span key={`${item.id}+equipable+${item.position}`}>
          {' '}
          <button onClick={() => toggleEquipped(item)}>
            {item.components.equipable.equipped ? 'Unequip' : 'Equip'}
          </button>
        </span>,
      );
    }
    if (item.components.enhanceable != null) {
      extraContent.push(
        <>
          <br />
          <span>
            Level: {item.components.enhanceable.level} EXP:
            {+item.components.enhanceable.exp.toFixed(2)} /{' '}
            {SmithingSkilling.getExpToLevelForItem(
              item.components.enhanceable.level,
            )}
          </span>
        </>,
      );
    }

    return (
      <span>
        <BaseItem item={item} />
        {extraContent}
        <button
          onClick={() => {
            deleteItem(item.position);
          }}
        >
          <Icon icon={SVGIconType['trash']} size={20} />
        </button>
      </span>
    );
  }
}

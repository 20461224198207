import { useEffect, useState } from 'react';
import {
  Route,
  Routes,
  useNavigate,
  useLocation,
  Link,
} from 'react-router-dom';
import LoginPage from './components/LoginPage.react';
import { getUser, saveUser, removeUser } from './db/UserStorage';
import User from './components/User.react';
export default function App() {
  const [accountId, setAccountId] = useState<null | string>(null);
  const navigate = useNavigate();
  const location = useLocation();

  const login = function (newUsername: string, newAccountId: string): void {
    setAccountId(newAccountId);
    saveUser(newUsername, newAccountId);
    navigate('/user/activity');
  };

  if (accountId === null) {
    const user = getUser();
    if (user.userId !== null) {
      setAccountId(user.userId);
    }
  }

  const logout = () => {
    setAccountId(null);
    removeUser();
    navigate('/');
  };

  useEffect(() => {
    if (location.pathname !== '/' && accountId === null) {
      navigate('/', { replace: true });
    } else if (location.pathname === '/' && accountId !== null) {
      navigate('/user/activity/', { replace: true });
    }
  }, [accountId, location.pathname, navigate]);

  return (
    <div className="App">
      <Routes>
        <Route path="/" element={<LoginPage saveUsername={login} />} />
        <Route
          path="/user/*"
          element={<User accountId={accountId} logout={logout} />}
        />
        <Route
          path="*"
          element={
            <>
              You found a bad page, go back{'  '}
              <Link to="/"> home</Link>
            </>
          }
        />
      </Routes>
    </div>
  );
}

import { gql, useMutation } from '@apollo/client';
import { useState } from 'react';

type LoginPageProps = {
  saveUsername: (newUsername: string, newUserId: string) => void;
};

const FIND_OR_CREATE_ACCOUNT = gql`
  mutation FindOrCreateAccount($username: String!) {
    findOrCreateAccount(username: $username) {
      username
      id
    }
  }
`;

export default function LoginPage({ saveUsername }: LoginPageProps) {
  const [username, setUsername] = useState('');
  const [upsertAccountResponse] = useMutation(FIND_OR_CREATE_ACCOUNT);

  const upsertUsername = async function (): Promise<void> {
    const response = await upsertAccountResponse({
      variables: { username: username },
    });
    const res = response.data.findOrCreateAccount;
    saveUsername(res.username, res.id);
  };

  return (
    <div>
      <label htmlFor="username">Enter your username: </label>
      <input
        name="username"
        type="text"
        value={username}
        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
          setUsername(e.target.value);
        }}
        onKeyDown={(e) => {
          e.key === 'Enter' && upsertUsername();
        }}
      ></input>
      <button onClick={upsertUsername}>Login</button>
    </div>
  );
}

import ItemTable, { IInvItem } from '../../db/ItemTable';
import { CharacterSheet } from '../util/types/CharacterSheetType';
import { BaseSkilling } from './BaseSkilling';

export class ForgingSkilling extends BaseSkilling {
  static base_tick_speed = 0.75;

  // completion count = e^(skillPower * 0.05) * (minutes / tick speed)
  calculateCompletionCount(
    timerLength: number,
    skillRequired: number,
    baseTickLength: number,
    sheet: CharacterSheet,
  ): number {
    const forgingPower = this.getSkillPower(sheet);

    const completionCount = Math.trunc(
      Math.exp((forgingPower - skillRequired) * 0.05) *
        (timerLength / 60 / baseTickLength),
    );
    return completionCount;
  }

  getSkillPower(sheet: CharacterSheet): number {
    return Number((1 + sheet.forgingLevel * 0.2).toPrecision(2));
  }

  getFarmString(completionCount: number, itemTarget: string): string {
    const barTarget =
      ItemTable[parseInt(itemTarget)].components.forgeable?.barTarget;
    const itemName = ItemTable[parseInt(barTarget ?? '0')].name;

    return `You forged ${completionCount} ${itemName}.`;
  }

  isUselessTimer(
    timerLength: number,
    sheet: CharacterSheet,
    itemTarget: string,
  ): string {
    if (timerLength === 0) {
      return '';
    }
    const item = ItemTable[parseInt(itemTarget)];
    if (item.components.forgeable == null) {
      return ForgingSkilling.useless_timer_string;
    }
    const completionCount = this.calculateCompletionCount(
      timerLength,
      item.components.forgeable.minimumSkill,
      item.components.forgeable.tickSpeed,
      sheet,
    );
    if (completionCount <= 0) {
      return ForgingSkilling.useless_timer_string;
    }
    if (this.getTargetOreQuantity(sheet, itemTarget) < completionCount) {
      return 'You have less ore than you can forge with this timer. You can perform this action but it will give you a limited number of ore.';
    }
    return '';
  }

  private getTargetOreQuantity(sheet: CharacterSheet, targetId: string) {
    return sheet.inventory.items
      .filter((item) => item.id === targetId)
      .map((item) => item.components.stackable?.quantity ?? 0)
      .reduce((acc, val) => acc + val, 0);
  }

  getTarget(itemId: string) {
    try {
      const item = ItemTable[parseInt(itemId)];
      if (item.components.forgeable == null) {
        throw new Error("Item isn't valid forging target");
      }

      return {
        baseTickLength: item.components.forgeable.tickSpeed,
        expValue: item.components.forgeable.expValue,
        skillRequired: item.components.forgeable.minimumSkill,
      };
    } catch (error) {
      throw error;
    }
  }

  updateInventory(
    oldSheet: CharacterSheet,
    itemTarget: string,
    completionCount: number,
  ): Array<IInvItem> {
    // TODO: add multipler calculation here?
    const barTargetId =
      ItemTable[parseInt(itemTarget)].components.forgeable?.barTarget;
    if (barTargetId == null) {
      return oldSheet.inventory.items;
    }
    const barTarget = ItemTable[parseInt(barTargetId)];
    const maxStackSize = barTarget.components.stackable?.stackSize;
    if (maxStackSize == null) {
      return oldSheet.inventory.items;
    }

    let oresToRemove = completionCount;

    // remove ore
    let newInv: Array<IInvItem> = [];
    oldSheet.inventory.items.forEach((item) => {
      if (
        item.id !== itemTarget ||
        item.components.stackable?.quantity == null
      ) {
        newInv.push(item);
        return;
      } else {
        let newItem = { ...item };
        if (newItem.components?.stackable?.quantity == null) {
          newInv.push(item);
          return;
        }
        if (item.components.stackable?.quantity > oresToRemove) {
          newItem.components.stackable.quantity -= oresToRemove;
          oresToRemove = 0;
          newInv.push(newItem);
          return;
        } else {
          oresToRemove -= newItem.components.stackable.quantity;
          return;
        }
      }
    });
    let toAllocate = completionCount - oresToRemove;
    newInv = newInv.map((item) => {
      if (
        item.id !== barTargetId ||
        item.components.stackable == null ||
        item.components.stackable.quantity === maxStackSize
      ) {
        return item;
      } else if (
        item.components.stackable?.quantity + completionCount <
        maxStackSize
      ) {
        let newItem = { ...item };
        if (newItem.components.stackable == null) {
          return item;
        }
        newItem.components.stackable.quantity += toAllocate;
        toAllocate = 0;
        return newItem;
      } else {
        let newItem = { ...item };
        if (newItem.components.stackable == null) {
          return item;
        }
        toAllocate -= maxStackSize - newItem.components.stackable.quantity;
        newItem.components.stackable.quantity = maxStackSize;
        return newItem;
      }
    });
    while (toAllocate > 0) {
      const position = this.getNextEmptySlot(newInv);
      newInv.push({
        id: barTarget.id,
        position: position,
        components: {
          stackable: {
            quantity: toAllocate > maxStackSize ? maxStackSize : toAllocate,
          },
        },
      });
      toAllocate -= maxStackSize;
    }

    return newInv;
  }

  getSheetUpdates(
    oldSheet: CharacterSheet,
    targetId: string,
    completionCount: number,
  ) {
    const item = this.getTarget(targetId);
    const newLevels = this.calculateNewLevels(
      completionCount,
      oldSheet.forgingExperience,
      oldSheet.forgingLevel,
      item.expValue,
    );

    const newSheet = {
      ...oldSheet,
      forgingLevel: newLevels.newLevel,
      forgingExperience: newLevels.newExperience,
      inventory: {
        items: this.updateInventory(oldSheet, targetId, completionCount),
      },
    };

    return {
      newSheet: newSheet,
      gainedExp: newLevels.gainedExp,
      gainedLevels: newLevels.gainedLevels,
    };
  }
}

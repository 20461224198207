export const prettyPrintTime = function (time: number): string {
  let minutes: number | string = Math.trunc(time / 60);
  let seconds: number | string = Math.trunc(time % 60);
  if (minutes < 10) {
    minutes = '0' + minutes;
  }
  if (seconds < 10) {
    seconds = '0' + seconds;
  }

  return `${minutes}:${seconds}`;
};

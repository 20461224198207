export type AppSettings = {
  isContinueModeEnabled?: boolean;
  selectedActivity: string;
  selectedTag?: string;
  timerLength: number;
};

export enum SettingOptions {
  isContinueModeEnabled = 'isContinueModeEnabled',
  selectedActivity = 'selectedActivity',
  selectedTag = 'selectedTag',
  timerLength = 'timerLength',
}

export const getAppSettings = function (): AppSettings {
  const appSettingsRaw = localStorage.getItem('appSettings');
  if (appSettingsRaw != null) {
    const appSettings = JSON.parse(appSettingsRaw);
    return appSettings;
  }
  const defaultAppSettings: AppSettings = {
    isContinueModeEnabled: true,
    selectedActivity: 'rockFarming',
    timerLength: 5 * 60,
  };
  return defaultAppSettings;
};

export const saveAppSettings = function (newAppSettings: AppSettings): void {
  localStorage.setItem('appSettings', JSON.stringify(newAppSettings));
  return;
};

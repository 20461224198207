import styled from 'styled-components';
import { getUser } from '../db/UserStorage';
import { Link, useLocation } from 'react-router-dom';

const NavBar = styled.div`
  padding-right: 1em;
  height: 100vh;
  width: 170px;
`;

const NavBarLink = styled(Link)`
  color: white;
`;

const SelectedNavBarLink = styled(Link)`
  style: bold;
  color: yellow;
`;

type NavBarProps = { logout: () => void; secondsFocused: number };

export default function Navbar({ logout, secondsFocused }: NavBarProps) {
  const { username } = getUser();

  const location = useLocation();
  const route = location.pathname.split('/');
  const currentPage = route[route.length - 1];

  const calculateFocusString = function () {
    let hoursFocused = 0;
    let minutesFocused = Math.trunc(secondsFocused / 60);
    if (minutesFocused > 60) {
      hoursFocused = Math.trunc(minutesFocused / 60) ?? 0;
      minutesFocused = minutesFocused % 60;
    }
    let returnString = `${
      hoursFocused > 0 ? `${hoursFocused} hours and ` : ''
    }${minutesFocused} minutes today`;

    return returnString;
  };

  return (
    <NavBar>
      Navbar{' '}
      <div>
        Currently logged in as: <br />
        {username ?? ''} <br />
        <button
          onClick={() => {
            logout();
          }}
        >
          Log out
        </button>
      </div>
      <div>You've been focused for:</div>
      <div> {calculateFocusString()}</div>
      <ul>
        <li>
          {currentPage === 'activity' ? (
            <SelectedNavBarLink to="activity">Activity</SelectedNavBarLink>
          ) : (
            <NavBarLink to="activity">Activity</NavBarLink>
          )}
        </li>
        <li>
          {currentPage === 'characterSheet' ? (
            <SelectedNavBarLink to="characterSheet">
              Character Sheet
            </SelectedNavBarLink>
          ) : (
            <NavBarLink to="characterSheet">Character Sheet</NavBarLink>
          )}
        </li>
        <li>
          {currentPage === 'timerLog' ? (
            <SelectedNavBarLink to="timerLog">Timer Log</SelectedNavBarLink>
          ) : (
            <NavBarLink to="timerLog">Timer Log</NavBarLink>
          )}
        </li>
      </ul>
    </NavBar>
  );
}

import { CHARSHEET_VERSION } from '../../../db/CharacterSheetStorage';
import { IInvItem, IItem } from '../../../db/ItemTable';
import { EquipSlot } from './EquipSlot';

export interface CharacterSheet {
  str: number;
  dex: number;
  int: number;
  luk: number;
  charSheetVersion: string;
  herbalismLevel: number;
  herbalismExperience: number;
  forgingLevel: number;
  forgingExperience: number;
  smithingLevel: number;
  smithingExperience: number;
  rockFarmingLevel: number;
  rockFarmingExperience: number;
  inventory: { items: Array<IInvItem> };
  gear: Record<EquipSlot, IItem | null>;
}
const defaultInv = {
  Pickaxe: null,
  Helmet: null,
};

const characterSheetDefault: CharacterSheet = {
  str: 1,
  dex: 1,
  int: 1,
  luk: 1,
  charSheetVersion: CHARSHEET_VERSION,
  herbalismLevel: 1,
  herbalismExperience: 0,
  forgingLevel: 1,
  forgingExperience: 0,
  smithingLevel: 1,
  smithingExperience: 0,
  rockFarmingLevel: 1,
  rockFarmingExperience: 0,
  inventory: { items: new Array<IInvItem>() },
  gear: defaultInv,
};

export { characterSheetDefault };

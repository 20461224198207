import React from 'react';
import './App.css';
import { BrowserRouter } from 'react-router-dom';
import ReactDOM from 'react-dom/client';
import { ApolloClient, InMemoryCache, ApolloProvider } from '@apollo/client';
import App from './App.react';

const client = new ApolloClient({
  uri:
    process.env?.REACT_APP_ENV_VAR?.trim() === 'development'
      ? 'http://127.0.0.1:4000/graphql'
      : 'https://server.productiquest.com/graphql',
  cache: new InMemoryCache(),
});

if (process.env?.REACT_APP_ENV_VAR?.trim() === 'development') {
  document.title = '[TEST] ProductiQuest';
}

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement,
);
root.render(
  <React.StrictMode>
    <ApolloProvider client={client}>
      <BrowserRouter>
        <App />
      </BrowserRouter>
    </ApolloProvider>
  </React.StrictMode>,
);

import styled from 'styled-components';

const ModalBackground = styled.div`
  box-sizing: border-box;
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
  position: absolute;
  background-color: rgba(34, 34, 34, 0.8);
  display: flex;
`;

const Modal = styled.div`
  margin: auto;
  border: 1px solid white;
  border-radius: 4px;
  width: 20vw;
  background-color: rgb(34, 34, 34);
`;

const ModalHeader = styled.div`
  padding: 0.25em 1em 0.25em 1em;
  width: 100%;
  box-sizing: border-box;
  background-color: rgb(68, 68, 68);
  border-bottom: 1px solid white;
`;

const ModalBody = styled.div`
  padding: 1em;
`;

type ConfirmationModalProps = {
  onConfirm: () => void;
  onCancel: () => void;
  header?: string;
  text: string;
};

export default function ConfirmationModal({
  onConfirm,
  onCancel,
  header,
  text,
}: ConfirmationModalProps) {
  return (
    <ModalBackground>
      <Modal>
        <ModalHeader>{header}</ModalHeader>
        <ModalBody>
          {text}
          <div>
            <button onClick={onConfirm}>Confirm</button>
            <button onClick={onCancel}>Cancel</button>
          </div>
        </ModalBody>
      </Modal>
    </ModalBackground>
  );
}

import { ReactNode } from 'react';
import ItemTable from '../db/ItemTable';
import SmithingTargetSelector from './skills/SmithingTargetSelector.react';

type TargetSelectorProps = {
  selectedActivity: string;
  setSelectedTarget: (target: ITarget) => void;
  selectedTarget: ITarget | undefined;
};

export interface ITarget {
  id: string;
  value: string;
}
export default function TargetSelector({
  selectedActivity,
  setSelectedTarget,
  selectedTarget,
}: TargetSelectorProps) {
  const buildSimpleTargetList = (targets: ITarget[]) => {
    return (
      <select
        onChange={(e) => {
          e.preventDefault();
          const item = ItemTable[Number(e.target.value)];
          const newTarget = {
            id: item.id,
            value: item.name,
          };
          setSelectedTarget(newTarget);
        }}
        value={selectedTarget?.id ?? '0'}
      >
        {targets.map((target: ITarget) => (
          <option value={target.id} key={target.id}>
            {target.value}
          </option>
        ))}
      </select>
    );
  };

  const buildTargetSelector = (): ReactNode => {
    let targets: Array<ITarget> = [];
    switch (selectedActivity) {
      case 'rockFarming':
        targets = [
          ...ItemTable.filter(
            (item) => item.components.rockFarmingTarget != null,
          ).map((item) => ({
            id: item.id,
            value: item.name,
          })),
        ];
        return buildSimpleTargetList(targets);
      case 'forging':
        targets = [
          ...ItemTable.filter((item) => item.components.forgeable != null).map(
            (item) => ({
              id: item.id,
              value: item.name,
            }),
          ),
        ];
        return buildSimpleTargetList(targets);
      case 'smithing':
        return (
          <SmithingTargetSelector
            setSelectedTarget={setSelectedTarget}
            selectedTarget={selectedTarget}
          />
        );
      default:
        return <></>;
    }
  };

  return <>{buildTargetSelector()}</>;
}

type TimerDisplayProps = {
  isRunning: boolean;
  seconds: number;
  minutes: number;
};

export const getFormattedTime = function ({
  minutes,
  isRunning,
  seconds,
}: TimerDisplayProps): string {
  let displayString = '';
  if (minutes < 10) {
    displayString += '0' + minutes + ':';
  } else {
    displayString += minutes + ':';
  }
  if (seconds < 10) {
    displayString += '0' + seconds;
  } else {
    displayString += seconds;
  }
  if (isRunning === true) {
    if (process.env?.REACT_APP_ENV_VAR?.trim() === 'development') {
      document.title = '[TEST] ' + displayString;
    } else {
      document.title = displayString;
    }
  }
  return displayString;
};

export default function TimerDisplay({
  seconds,
  isRunning,
  minutes,
}: TimerDisplayProps) {
  return (
    <div>
      {getFormattedTime({
        minutes,
        isRunning,
        seconds,
      })}
    </div>
  );
}

export const saveUser = function (username: string, userId: string): void {
  localStorage.setItem('username', JSON.stringify(username));
  localStorage.setItem('userId', JSON.stringify(userId));
};

export const getUser = function (): { username: string; userId: string } {
  const username = localStorage.getItem('username');
  const userId = localStorage.getItem('userId');
  return {
    username: username ? JSON.parse(username) : null,
    userId: userId ? JSON.parse(userId) : null,
  };
};

export const removeUser = function (): void {
  localStorage.removeItem('username');
  localStorage.removeItem('userId');
};

import { createContext } from 'react';
import {
  CharacterSheet,
  characterSheetDefault,
} from './types/CharacterSheetType';

type SheetContextType = {
  characterSheet: CharacterSheet;
  setCharacterSheet: (newSheet: CharacterSheet) => void;
};

export const CharacterSheetContext = createContext<SheetContextType>({
  characterSheet: characterSheetDefault,
  setCharacterSheet: () => {},
});

import ItemTable, { IInvItem } from '../../db/ItemTable';
import { CharacterSheet } from '../util/types/CharacterSheetType';

export abstract class BaseSkilling {
  static base_tick_speed = 0.75;
  static useless_timer_string = `Warning! With your current skills and gear this timer will return 0 rewards! Use a longer timer or pick an easier target!`;

  abstract getTarget(itemId: string): {
    baseTickLength: number;
    expValue: number;
    skillRequired: number;
  };
  abstract getSheetUpdates(
    oldSheet: CharacterSheet,
    targetId: string,
    completionCount: number,
  ): {
    newSheet: CharacterSheet;
    gainedExp: number;
    gainedLevels: number;
  };
  abstract getSkillPower(sheet: CharacterSheet): number;
  abstract getFarmString(completionCount: number, itemTarget: string): string;
  abstract calculateCompletionCount(
    timerLength: number,
    targetId: number,
    baseTickLength: number,
    sheet: CharacterSheet,
  ): number;

  public calculateEndLevelAndExp(
    prevLevel: number,
    prevExp: number,
    addedExp: number,
    getExpToNextLevelFn: (prevLevel: number) => number,
  ): { finalLevel: number; finalExp: number } {
    let finalVals = {
      finalLevel: prevLevel,
      finalExp: prevExp,
    };
    while (addedExp > 0) {
      const toNextLevel = getExpToNextLevelFn(prevLevel);
      if (addedExp + finalVals.finalExp < toNextLevel) {
        finalVals.finalExp += addedExp;
        addedExp = 0;
      } else {
        finalVals.finalLevel++;
        const expLeftToLevel = toNextLevel - finalVals.finalExp;
        addedExp -= expLeftToLevel;
        finalVals.finalExp = 0;
      }
    }
    return finalVals;
  }

  public calculateSession(
    timerLength: number,
    sheet: CharacterSheet,
    itemTarget: string,
  ): { sheet: CharacterSheet; resultString: string } {
    try {
      const { baseTickLength, expValue, skillRequired } =
        this.getTarget(itemTarget);

      const completionCount = this.calculateCompletionCount(
        timerLength,
        skillRequired,
        baseTickLength,
        sheet,
      );

      const sheetResults = this.getSheetUpdates(
        sheet,
        itemTarget,
        completionCount,
      );

      const resultString = this.getResultString(
        sheetResults.gainedLevels,
        completionCount * expValue,
        timerLength,
        itemTarget,
        completionCount,
      );

      return { sheet: sheetResults.newSheet, resultString };
    } catch (error) {
      console.error('hit an error trying to save the update sheet');
      console.error(error);
      throw error;
    }
  }

  // TODO: TEST THIS
  public getNextEmptySlot(inv: IInvItem[]): number {
    let slots = inv.map((item) => item.position).sort((a, b) => a - b);
    for (let i = 0; i < slots.length; i++) {
      if (i !== slots[i]) {
        return i;
      }
    }
    return inv.length;
  }

  private getResultString(
    gainedLevels: number,
    gainedExp: number,
    timerLength: number,
    itemTarget: string,
    completionCount: number,
  ): string {
    let resultString = `You were focused for ${Math.trunc(
      timerLength / 60,
    )} minutes.  `;
    if (gainedLevels) {
      resultString += `You gained ${gainedExp} experience which gained you ${gainedLevels} ${
        gainedLevels > 1 ? 'levels' : 'level'
      }.`;
    } else {
      resultString += `You gained ${gainedExp} experience.`;
    }

    // TODO: this should maybe be called from inside the calc result function and passed through
    resultString += `  ${this.getFarmString(completionCount, itemTarget)}`;

    return resultString;
  }

  //FIXME: this might need to be in the child classes
  isUselessTimer(
    timerLength: number,
    sheet: CharacterSheet,
    itemTarget: string,
  ): string {
    if (timerLength === 0) {
      return '';
    }
    const { baseTickLength, skillRequired } = this.getTarget(itemTarget);

    const completionCount = this.calculateCompletionCount(
      timerLength,
      skillRequired,
      baseTickLength,
      sheet,
    );
    return completionCount > 0 ? '' : BaseSkilling.useless_timer_string;
  }

  calculateNewLevels(
    completionCount: number,
    currentExp: number,
    currentLevel: number = 1,
    expVal: number,
  ): {
    gainedLevels: number;
    newLevel: number;
    gainedExp: number;
    newExperience: number;
  } {
    const expGained = Math.trunc(completionCount) * expVal;
    let gainedExp = (currentExp ?? 0) + expGained;
    let totalExp = (currentExp ?? 0) + expGained;

    let levelCount = 0;
    while (totalExp >= currentLevel * 20 * 1.25) {
      ++levelCount;
      totalExp = totalExp - currentLevel * 20 * 1.25;
      currentLevel += 1;
    }

    return {
      gainedLevels: levelCount,
      newLevel: currentLevel,
      gainedExp: gainedExp,
      newExperience: totalExp,
    };
  }

  updateInventory(
    sheet: CharacterSheet,
    itemTarget: string,
    completionCount: number,
  ): Array<IInvItem> {
    const maxStackSize =
      ItemTable[parseInt(itemTarget)].components.stackable?.stackSize;
    if (maxStackSize == null) {
      throw new Error("item isn't stackable");
    }
    let toAllocate = completionCount;
    let newInv = sheet.inventory.items.map((item) => {
      if (
        item.id !== itemTarget ||
        item.components.stackable == null ||
        item.components.stackable.quantity === maxStackSize
      ) {
        return item;
      } else if (
        item.components.stackable?.quantity + completionCount <
        maxStackSize
      ) {
        let newItem = { ...item };
        if (newItem.components.stackable == null) {
          return item;
        }
        newItem.components.stackable.quantity += toAllocate;
        toAllocate = 0;
        return newItem;
      } else {
        let newItem = { ...item };
        if (newItem.components.stackable == null) {
          return item;
        }
        toAllocate -= maxStackSize - newItem.components.stackable.quantity;
        newItem.components.stackable.quantity = maxStackSize;
        return newItem;
      }
    });
    while (toAllocate > 0) {
      const position = this.getNextEmptySlot(newInv);
      newInv.push({
        id: itemTarget,
        position: position,
        components: {
          stackable: {
            quantity: toAllocate > maxStackSize ? maxStackSize : toAllocate,
          },
        },
      });
      toAllocate -= maxStackSize;
    }
    return newInv;
  }
}

import { gql, useMutation } from '@apollo/client';
import { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { AppSettings, SettingOptions } from '../db/SettingsStorage';
import ConfirmationModal from './common/ConfirmationModal.react';
import TagAdder from './TagAdder.react';
import TagSelector from './TagSelector.react';
import { ITag } from './util/types/TimerType';

const ADD_TAG_TO_USER = gql`
  mutation AddTagToUser($newTag: String!, $userId: String!) {
    addTagToUser(newTag: $newTag, userId: $userId) {
      id
      username
      tags {
        id
        value
      }
    }
  }
`;

const DELETE_TAG_FROM_USER = gql`
  mutation DeleteTag($tagId: String!) {
    deleteTag(tagId: $tagId) {
      value
      deleted
    }
  }
`;

export interface UpdateTagInterface {
  id: string;
  value: string;
}

type TagContainerProps = {
  accountId: string;
  settings: AppSettings;
  tagsFromParent: Array<ITag>;
  updateSettings: (tag: SettingOptions, input: string) => void;
  updateSelectedTag: (values: UpdateTagInterface) => void;
};

function TagContainer({
  accountId,
  settings,
  tagsFromParent,
  updateSettings,
  updateSelectedTag,
}: TagContainerProps) {
  const [isTagSelectorVisible, setIsTagSelectorVisible] = useState(true);
  const [tags, setTags] = useState<ITag[]>([]);
  const [addTagToUser] = useMutation(ADD_TAG_TO_USER);
  const [isDeleteConfirmVisible, setIsDeleteConfirmVisible] = useState(false);
  const [deleteTagMutation] = useMutation(DELETE_TAG_FROM_USER);

  const selectTag = (input: string) => {
    const tagId = tags.filter((tag) => tag.value === input)[0].id;
    updateSelectedTag({ id: tagId, value: input });
    updateSettings(SettingOptions.selectedTag, input);
  };

  useEffect(() => {
    if (tagsFromParent != null) {
      setTags(
        tagsFromParent
          .filter((tag: ITag) => tag.deleted !== true)
          .map((tagContainer: ITag) => {
            return { value: tagContainer.value, id: tagContainer.id };
          }),
      );
    }
  }, [tagsFromParent]);

  useEffect(() => {
    let tagId = '';
    if (tags.length > 0) {
      tagId = tags
        .filter((tag) => tag.deleted !== true)
        .filter((tag) => tag.value === settings.selectedTag)[0]?.id;
    }
    if (tagId == null && tags.length > 0) {
      if (tagId !== null) {
        updateSelectedTag({ id: tagId, value: tags[0].value });
        updateSettings(SettingOptions.selectedTag, tags[0].value);
      } else {
        const selectedTag = getFirstAvailableTag(tags);
        updateSelectedTag({
          id: selectedTag?.id ?? '',
          value: selectedTag?.value ?? '',
        });
        updateSettings(SettingOptions.selectedTag, tags[0].value);
      }
    } else {
      updateSelectedTag({ id: tagId, value: settings.selectedTag ?? '' });
    }
  }, [updateSettings, settings.selectedTag, tags, updateSelectedTag]);

  const saveNewTag = async function (newTag: string): Promise<void> {
    await addTagToUser({
      variables: { newTag: newTag, userId: accountId },
    });

    setIsTagSelectorVisible(!isTagSelectorVisible);
  };

  const getFirstAvailableTag = (
    tags: Array<ITag>,
  ): { id: string; value: string } | null => {
    if (tags === null || tags.length === 0) {
      return null;
    }
    const nonDeletedTags = tags.filter((tag: ITag) => tag.deleted !== false);
    return { id: nonDeletedTags[0].id, value: nonDeletedTags[0].value };
  };

  const deleteTag = async (): Promise<void> => {
    try {
      const deletedTagId = tags.filter(
        (tag) => tag.value === settings.selectedTag,
      )[0]?.id;

      await deleteTagMutation({
        variables: { tagId: deletedTagId },
      });
      setIsDeleteConfirmVisible(false);

      toast(`Successfully deleted ${settings.selectedTag}`);

      const newTags = tags.filter((tag) => tag.id !== deletedTagId);
      setTags(newTags);
    } catch (error) {
      toast(`Encountered an error deleting tag`);
    }
    setIsDeleteConfirmVisible(false);
  };

  return (
    <div>
      {isTagSelectorVisible ? (
        <div>
          <div>
            <TagSelector
              tags={tags}
              setSelectedTag={(input: string) => selectTag(input)}
              selectedTag={settings.selectedTag ?? ''}
            />
            <button
              disabled={tags.length === 0}
              onClick={() => {
                setIsDeleteConfirmVisible(true);
              }}
            >
              Delete tag
            </button>
          </div>
          <div>
            <button
              onClick={() => {
                setIsTagSelectorVisible(!isTagSelectorVisible);
              }}
            >
              Add new tag
            </button>
          </div>
        </div>
      ) : (
        <div>
          <TagAdder
            addTag={saveNewTag}
            cancelAdd={() => {
              setIsTagSelectorVisible(!isTagSelectorVisible);
            }}
          />
        </div>
      )}
      {isDeleteConfirmVisible && (
        <ConfirmationModal
          header="Delete Tag"
          text={`Are you sure you want to delete the tag: "${
            settings.selectedTag ?? ''
          }"`}
          onConfirm={() => {
            deleteTag();
          }}
          onCancel={() => setIsDeleteConfirmVisible(false)}
        />
      )}
    </div>
  );
}

export default TagContainer;

import { useContext, useEffect, useState } from 'react';
import { SmithingCategories } from '../util/types/SmithingCategories';
import ItemTable, { IItem } from '../../db/ItemTable';
import { ITarget } from '../TargetSelector';
import { CharacterSheetContext } from '../util/CharacterSheetContext';

type CategoryString = keyof typeof SmithingCategories;
type CategorySelectorTypes = {
  selectedTarget: ITarget | undefined;
  setSelectedTarget: (newVal: ITarget) => void;
};

export default function SmithingTargetSelector({
  selectedTarget,
  setSelectedTarget,
}: CategorySelectorTypes) {
  const { characterSheet } = useContext(CharacterSheetContext);
  const [selectedCategory, setSelectedCategory] =
    useState<keyof typeof SmithingCategories>('tierone');
  useEffect(() => {
    const targ: ITarget = { id: '4', value: 'Copper Pickaxe' };
    setSelectedTarget(targ);
  }, []);
  return (
    <div>
      <select
        onChange={(e) => {
          e.preventDefault();
          const newSelectedCategory = e.target
            .value as keyof typeof SmithingCategories;
          setSelectedCategory(newSelectedCategory);
          if (newSelectedCategory === 'enhanceablegear') {
            const firstItem = characterSheet.inventory.items.find(
              (item) => item.components.enhanceable,
            );
            if (firstItem != null) {
              const iItem = ItemTable[Number(firstItem.id)];
              setSelectedTarget({
                id: String(firstItem.position * -1),
                value: iItem.name,
              });
            }
          } else {
            const newTargetItem = ItemTable.filter((item) => {
              const itemCategory = item?.components?.smithable?.category;
              const targetCategory =
                SmithingCategories[
                  newSelectedCategory as keyof typeof SmithingCategories
                ];
              return itemCategory === targetCategory;
            })[0];
            if (newTargetItem == null) {
              throw new Error('Target item cannot be found');
            } else {
              setSelectedTarget({
                id: newTargetItem.id,
                value: newTargetItem.name,
              });
            }
          }
        }}
        value={selectedCategory}
      >
        {(
          Object.keys(SmithingCategories) as (keyof typeof SmithingCategories)[]
        ).map((category: CategoryString) => {
          return (
            <option value={category} key={category}>
              {SmithingCategories[category]}
            </option>
          );
        })}
      </select>
      {selectedCategory === 'enhanceablegear' ? (
        // get all items in inv that are enhanceable, look for component
        <select
          onChange={(e) => {
            e.preventDefault();
            const invItem = characterSheet.inventory.items.filter(
              (item) => item.position === Math.abs(Number(e.target.value)),
            )[0];
            const item = ItemTable[Number(invItem.id)];
            setSelectedTarget({ id: e.target.value, value: item.name });
          }}
        >
          <option value="" disabled key="defaultVal">
            Select an item to enhance
          </option>
          {characterSheet.inventory.items
            .filter(
              (item) =>
                item?.components?.enhanceable &&
                item?.components?.equipable?.equipped === false,
            )
            .map((item) => (
              <option value={item.position * -1} key={item.position}>
                {`${ItemTable[Number(item.id)].name} Level: ${
                  item.components.enhanceable?.level
                } Exp: ${+item!.components!.enhanceable!.exp.toFixed(2)}`}
              </option>
            ))}
        </select>
      ) : (
        <>
          <select
            onChange={(e) => {
              e.preventDefault();
              const item = ItemTable[Number(e.target.value)];
              const newTarget = {
                id: item.id,
                value: item.name,
              };
              setSelectedTarget(newTarget);
            }}
            value={selectedTarget?.id ?? '0'}
          >
            {ItemTable.filter(
              (item) =>
                item?.components?.smithable?.category ===
                SmithingCategories[
                  selectedCategory as keyof typeof SmithingCategories
                ],
            ).map((target: IItem) => (
              <option value={target.id} key={target.id}>
                {target.name}
              </option>
            ))}
          </select>
          <div>
            Required Materials
            <ul>
              {ItemTable[
                Number(selectedTarget?.id) ?? 0
              ]?.components.smithable?.materials.map((item) => (
                <li key={item.itemId}>
                  {ItemTable[Number(item.itemId)].name}: {item.quantity}
                </li>
              ))}
            </ul>
          </div>
        </>
      )}
    </div>
  );
}

import styled from 'styled-components';
import ItemTable, { IInvItem, IItem, IItemComponents } from '../db/ItemTable';
import { ReactNode } from 'react';
import { SmithingSkilling } from './skills/Smithing';

import {
  GearStat,
  prettyPrintStat,
  Stat,
} from '../components/util/types/Stats';
const ItemCardContainer = styled.div`
  background: white;
  display: flex;
  flex-direction: column;
  width: 200px;
  padding: 5px;
`;

const EnhancementSection = styled.div`
  border: 1px solid black;
  padding: 4px 0 6px 8px;
  margin-top: 10px;
  position: relative;
  border-radius: 3px;
`;

const EnhancementHeader = styled.span`
  background: white;
  position: absolute;
  width: auto;
  top: -11px;
  left: 16px;
  padding: 0 4px 0 4px;
`;

type TypeObj = Record<keyof IItemComponents, undefined>;
const properties: TypeObj = {
  stackable: undefined,
  rockFarmingTarget: undefined,
  forgeable: undefined,
  smithable: undefined,
  equipable: undefined,
  enhanceable: undefined,
};

const itemComponents = Object.keys(properties);

export default function ItemCard(props: { invItem: IInvItem }) {
  const item = ItemTable[Number(props.invItem.id)];

  let addtlContent: Array<ReactNode> = [];
  itemComponents.forEach((component: string) => {
    switch (component) {
      case 'stackable':
        if (item.components.stackable != null) {
          addtlContent.push(
            <div>
              {props.invItem!.components!.stackable!.quantity} /{' '}
              {item.components.stackable.stackSize}
            </div>,
          );
        }
        break;
      case 'rockFarmingTarget':
        if (item.components.rockFarmingTarget != null) {
          // addtlContent.push(<div>rock farmable!</div>);
        }
        break;
      case 'forgeable':
        if (item.components.forgeable != null) {
          // addtlContent.push(<div>forgeable</div>);
        }
        break;
      case 'smithable':
        if (item.components.smithable != null) {
          // addtlContent.push(<div>Smithable</div>);
        }
        break;
      case 'equipable':
        if (item.components.equipable != null) {
          addtlContent.push(<div>{StatBlock(item)}</div>);
        }
        break;
      case 'enhanceable':
        if (item.components.enhanceable != null) {
          addtlContent.push(
            <EnhancementSection>
              <EnhancementHeader>Enhancement</EnhancementHeader>
              <div>
                <div>
                  +
                  {SmithingSkilling.getEnhancementBonus(
                    props.invItem.components!.enhanceable!.level,
                  )}{' '}
                  to all stats
                </div>
                <div>Level: {props.invItem.components.enhanceable!.level}</div>
                <div>
                  EXP:
                  {+props.invItem.components.enhanceable!.exp.toFixed(2)} /{' '}
                  {SmithingSkilling.getExpToLevelForItem(
                    props.invItem.components.enhanceable!.level,
                  )}
                </div>
              </div>
            </EnhancementSection>,
          );
        }
    }
  });

  return (
    <ItemCardContainer>
      <b>{item.name}</b>
      <div>{addtlContent.map((content) => content)}</div>
    </ItemCardContainer>
  );
}

function StatBlock(item: IItem) {
  type gearKeys = keyof typeof GearStat;
  const gearStats: ReactNode = (Object.keys(GearStat) as gearKeys[]).map(
    (stat) => {
      const val = item.components.equipable!.statsGranted[stat];
      if (val > 0) {
        return (
          <div>
            {prettyPrintStat(GearStat[stat])}: {val}
          </div>
        );
      }
      return <></>;
    },
  );

  return (
    <div>
      <div>Str: {item.components.equipable?.statsGranted.str}</div>
      <div>Dex: {item.components.equipable?.statsGranted.dex}</div>
      <div>Int: {item.components.equipable?.statsGranted.int}</div>
      <div>Luk: {item.components.equipable?.statsGranted.luk}</div>
      {gearStats}
    </div>
  );
}

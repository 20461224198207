import ItemTable, {
  ICraftingComponent,
  IInvItem,
  IItem,
} from '../../db/ItemTable';
import { CharacterSheet } from '../util/types/CharacterSheetType';
import { BaseSkilling } from './BaseSkilling';

export class SmithingSkilling extends BaseSkilling {
  static base_tick_speed = 0.75;

  // completion count = e^(skillPower * 0.05) * (minutes / tick speed)
  calculateCompletionCount(
    timerLength: number,
    targetId: number,
    baseTickLength: number,
    sheet: CharacterSheet,
  ): number {
    const smithingPower = this.getSkillPower(sheet);
    const itemTarget = this.getTargetAsItem(String(targetId), sheet);

    const targetCompletionCount = Math.trunc(
      Math.exp(
        (smithingPower - (itemTarget.components.smithable?.minimumSkill ?? 0)) *
          0.05,
      ) *
        (timerLength / 60 / baseTickLength),
    );
    const invCompletionCount = this.calculateInventorySupportedCompletionCount(
      targetCompletionCount,
      itemTarget,
      sheet,
    );
    return invCompletionCount;
  }

  calculateInventorySupportedCompletionCount(
    targetCompletionCount: number,
    itemTarget: IItem,
    sheet: CharacterSheet,
  ): number {
    // create components array with all components
    if (itemTarget.components.smithable == null) {
      return 0;
    }
    const components: any = [...itemTarget.components.smithable.materials];
    const inv = sheet.inventory.items;
    // sort through inv totaling components
    components.forEach((mat: any) => {
      const matID = mat.itemId;
      const totalQuantity = inv
        .filter((item) => item.id === matID)
        .reduce((total, item) => {
          if (item.components.stackable?.quantity == null) return 0;
          return total + item.components.stackable?.quantity;
        }, 0);
      // divide each by required amount per craft
      const completionCount = totalQuantity / mat.quantity;
      components[0]['completionCount'] = completionCount;
    });
    const completionCountArr = components.map(
      (component: any) => component.completionCount,
    );
    // return lowest of those vals
    const lowestCompletionCount = Math.min(completionCountArr);
    return targetCompletionCount < lowestCompletionCount
      ? targetCompletionCount
      : lowestCompletionCount;
  }

  isUselessTimer(
    timerLength: number,
    sheet: CharacterSheet,
    itemTarget: string,
  ): string {
    if (timerLength === 0) {
      return '';
    }
    const item = this.getTargetAsItem(itemTarget, sheet);
    if (item.components.smithable == null) {
      return SmithingSkilling.useless_timer_string;
    }
    const completionCount = this.calculateCompletionCount(
      timerLength,
      parseInt(itemTarget),
      item.components.smithable.tickSpeed,
      sheet,
    );
    // I am NOT sure why I wrote this like this, leaving it for now
    // to maybe spark something later
    // const completionCount = this.calculateCompletionCount(
    //   timerLength,
    //   item.components.smithable.minimumSkill,
    //   item.components.smithable.tickSpeed,
    //   sheet,
    // );
    if (completionCount <= 0) {
      return SmithingSkilling.useless_timer_string;
    }

    return '';
  }

  getTargetAsItem(itemId: string, sheet?: CharacterSheet): IItem {
    try {
      let item = null;
      if (Number(itemId) < 0) {
        const invItemId = sheet?.inventory.items.find(
          (item) => item.position === Math.abs(Number(itemId)),
        )?.id;
        if (invItemId == null) {
          throw new Error('Cannot find item that matches ID');
        }
        item = ItemTable[Number(invItemId)];
      } else {
        item = ItemTable[parseInt(itemId)];
      }

      return item;
    } catch (error) {
      throw error;
    }
  }

  getTarget(itemId: string, sheet?: CharacterSheet) {
    try {
      let item = null;
      if (Number(itemId) < 0) {
        const invItemId = sheet?.inventory.items.find(
          (item) => item.position === Math.abs(Number(itemId)),
        )?.id;
        if (invItemId == null) {
          throw new Error('Cannot find item that matches ID');
        }
        item = ItemTable[Number(invItemId)];
      } else {
        item = ItemTable[parseInt(itemId)];
      }
      if (item.components.smithable == null) {
        throw new Error("Item isn't valid smithing target");
      }
      return {
        baseTickLength: item.components.smithable.tickSpeed,
        expValue: item.components.smithable.expValue,
        skillRequired: item.components.smithable.minimumSkill,
      };
    } catch (error) {
      throw error;
    }
  }

  getStackableItemUpdates(
    craftingMats: Array<ICraftingComponent>,
    completionCount: number,
    sheet: CharacterSheet,
    targetId: string,
  ): Array<IInvItem> {
    let totalCraftingMats = craftingMats.map((material) => {
      return {
        itemId: material.itemId,
        quantity: material.quantity * completionCount,
      };
    });
    let materialIds = craftingMats.map((material) => material.itemId);

    const itemInterface = ItemTable[Number(targetId)];
    let newInv: Array<IInvItem> = [];
    sheet.inventory.items.forEach((item) => {
      // if the item isn't an item we need to delete or add push it onto
      // the inv and move on
      if (!materialIds.includes(item.id) && item.id !== targetId) {
        newInv.push(item);
        // item is the target item and we still have some to distribute
      } else if (item.id === targetId && completionCount > 0) {
        const amountToTransfer =
          itemInterface.components.stackable!.stackSize -
          item.components.stackable!.quantity;
        let newItem = { ...item };
        if (completionCount <= amountToTransfer) {
          newItem.components.stackable!.quantity += completionCount;
          completionCount = 0;
          newInv.push(newItem);
        } else {
          const diffCount =
            itemInterface.components.stackable!.stackSize -
            newItem.components.stackable!.quantity;
          newItem.components.stackable!.quantity =
            itemInterface.components.stackable!.stackSize;
          completionCount -= diffCount;
          newInv.push(newItem);
        }
        // the item we're looking at needs to be removed from
      } else if (materialIds.includes(item.id)) {
        const materialArrayIndex = totalCraftingMats.findIndex(
          (material) => material.itemId === item.id,
        );

        if (
          totalCraftingMats[materialArrayIndex].quantity <
          item.components.stackable!.quantity
        ) {
          let newItem = { ...item };
          newItem.components.stackable!.quantity -=
            totalCraftingMats[materialArrayIndex].quantity;
          totalCraftingMats.splice(materialArrayIndex, 1);
          newInv.push(newItem);
        } else {
          totalCraftingMats[materialArrayIndex].quantity -=
            item.components.stackable!.quantity;
        }
      } else {
        newInv.push(item);
      }
    });

    while (completionCount > 0) {
      const position = this.getNextEmptySlot(newInv);
      const buildTargetComponents = this.getBuildTargetComponents(
        targetId,
        completionCount,
      );
      newInv.push({
        id: targetId,
        position: position,
        components: buildTargetComponents,
      });
    }
    return newInv;
  }

  getBuildTargetComponents(targetId: string, completionCount: number) {
    const item = ItemTable[Number(targetId)];
    let components: Record<string, any> = {};
    if (item.components.stackable) {
      components.stackable = {
        quantity:
          completionCount > item.components.stackable.stackSize
            ? item.components.stackable.stackSize
            : completionCount,
      };
    }
    if (item.components.equipable) {
      components.equiable = {
        equipped: false,
        slot: item.components.equipable.equipSlot,
        stats: { ...item.components.equipable.statsGranted },
      };
    }
    if (item.components.enhanceable) {
      components.enhanceable = {
        exp: 0,
        level: 0,
      };
    }
    return components;
  }

  updateInventory(
    oldSheet: CharacterSheet,
    itemTargetId: string,
    completionCount: number,
  ): Array<IInvItem> {
    // TODO: add multipler calculation here?
    const smithingTarget = this.getTargetAsItem(itemTargetId, oldSheet);
    if (!smithingTarget.components.smithable) {
      return oldSheet.inventory.items;
    }

    let newInv: Array<IInvItem> = oldSheet.inventory.items;
    if (smithingTarget.components.stackable != null) {
      newInv = this.getStackableItemUpdates(
        smithingTarget.components.smithable.materials,
        completionCount,
        oldSheet,
        itemTargetId,
      );
    } else {
      let components = [...smithingTarget.components.smithable.materials];
      // TODO: Verify that this only runs if inv has the stuff -- It does, but the return val is confused
      //       it reports success even without adequate items in inv
      newInv = oldSheet.inventory.items;
      while (components.length > 0) {
        try {
          let itemId = components[0].itemId;
          let index = newInv.findIndex((item) => item.id === itemId);
          while (index >= 0 && components[0].itemId === itemId) {
            if (
              newInv[index].components.stackable!.quantity >
              components[0].quantity
            ) {
              newInv[index].components.stackable!.quantity -=
                components[0].quantity;
              components.splice(0, 1);
            } else if (
              newInv[index].components.stackable!.quantity <
              components[0].quantity
            ) {
              components[0].quantity -=
                newInv[index].components.stackable!.quantity;
              newInv.splice(index, 1);
            } else if (
              newInv[index].components.stackable!.quantity ===
              components[0].quantity
            ) {
              newInv.splice(index, 1);
              components.splice(0, 1);
            }
          }
          //LONGTERM: lol empty catch
        } catch {}
      }
      if (Number(itemTargetId) < 0) {
        const enhancedItemPosition = Math.abs(Number(itemTargetId));
        const enhancedItemIndex = newInv.findIndex(
          (item) => item.position === enhancedItemPosition,
        );
        const invItem = newInv[enhancedItemIndex];
        if (invItem?.components?.enhanceable != null) {
          const finalStats = this.calculateEndLevelAndExp(
            invItem.components.enhanceable.level,
            invItem.components.enhanceable.exp,
            completionCount,
            (prevLevel: number) => Math.round(((prevLevel + 1) * 2) ^ 2.5) + 20,
          );
          newInv[enhancedItemIndex].components.enhanceable = {
            exp: finalStats.finalExp,
            level: finalStats.finalLevel,
          };
        }
      } else {
        const position = this.getNextEmptySlot(newInv);
        const createdItemSlot = smithingTarget.components.equipable?.equipSlot;
        const createdItem = ItemTable[Number(itemTargetId)];
        if (createdItemSlot == null) return oldSheet.inventory.items;

        const finalStats = this.calculateEndLevelAndExp(
          0,
          0,
          completionCount,
          (prevLevel: number) => Math.round(((prevLevel + 1) * 2) ^ 2.5) + 20,
        );

        const item = {
          id: itemTargetId,
          position: position,
          components: {
            equipable: {
              equipped: false,
              slot: createdItemSlot,
              stats: createdItem!.components!.equipable!.statsGranted,
            },
            enhanceable: {
              exp: finalStats.finalExp,
              level: finalStats.finalLevel,
            },
          },
        };
        newInv.push(item);
      }
    }

    return newInv;
  }

  public calculateSession(
    timerLength: number,
    sheet: CharacterSheet,
    itemTarget: string,
  ): { sheet: CharacterSheet; resultString: string } {
    try {
      // figure out it if it can run
      let item = null;
      item = this.getTarget(itemTarget, sheet);
      const itemInt = this.getTargetAsItem(itemTarget, sheet);
      const completionCount = this.calculateCompletionCount(
        timerLength,
        parseInt(itemTarget),
        item.baseTickLength,
        sheet,
      );
      const invCompletionCount =
        this.calculateInventorySupportedCompletionCount(
          completionCount,
          itemInt,
          sheet,
        );

      let sheetResults = sheet;
      let updateSheet = {
        newSheet: sheet,
        gainedExp: 0,
        gainedLevels: 0,
      };
      if (Math.trunc(invCompletionCount) > 0) {
        // TODO: add else statement that handles if 0 completions
        updateSheet = this.getSheetUpdates(
          sheet,
          itemTarget,
          invCompletionCount,
        );
        sheetResults = updateSheet.newSheet;
      }
      return {
        sheet: sheetResults,
        resultString: this.getSmithingResultsString(
          completionCount,
          updateSheet.gainedLevels,
          updateSheet.gainedExp,
          timerLength,
          itemTarget,
          sheetResults,
        ),
      };
    } catch (error) {
      console.error('hit an error trying to save the update sheet');
      console.error(error);
      throw error;
    }
  }

  private getSmithingResultsString(
    completionCount: number,
    gainedLevels: number,
    gainedExp: number,
    timerLength: number,
    itemTarget: string,
    sheet: CharacterSheet,
  ): string {
    const focusMinutes = Math.trunc(timerLength / 60);
    const item = this.getTargetAsItem(itemTarget, sheet);
    let resultString = `You were focused for ${focusMinutes} minutes.  `;
    if (gainedLevels) {
      resultString += `You gained ${gainedExp} experience which gained you ${gainedLevels} ${
        gainedLevels > 1 ? 'levels' : 'level'
      }.`;
    } else {
      resultString += `You gained ${gainedExp} experience.`;
    }

    if (Number(itemTarget) > 0) {
      if (item.components.stackable != null) {
        resultString += ` You created (${completionCount}) ${
          item.name + (completionCount > 1 && 's')
        }`;
      } else {
        resultString += `  You created a ${item.name}`;
      }
    } else {
      const invItem = sheet.inventory.items.filter(
        (item) => item.position === Math.abs(Number(itemTarget)),
      )[0];
      if (invItem.components.enhanceable!.exp < completionCount) {
        resultString += `  Your ${item.name} gained ${completionCount} exp, which caused that item to level up!`;
      } else {
        resultString += `  Your ${item.name} gained ${completionCount} exp.`;
      }
    }
    return resultString;
  }

  getSheetUpdates(
    oldSheet: CharacterSheet,
    targetId: string,
    completionCount: number,
  ) {
    const item = this.getTarget(targetId, oldSheet);
    const newLevels = this.calculateNewLevels(
      completionCount,
      oldSheet.smithingExperience,
      oldSheet.smithingLevel,
      item.expValue,
    );

    const newSheet = {
      ...oldSheet,
      smithingLevel: newLevels.newLevel,
      smithingExperience: newLevels.newExperience,
      inventory: {
        items: this.updateInventory(oldSheet, targetId, completionCount),
      },
    };

    return {
      newSheet: newSheet,
      gainedExp: newLevels.gainedExp,
      gainedLevels: newLevels.gainedLevels,
    };
  }

  public static getExpToLevelForItem(currentLevel: number): number {
    return Math.round(((currentLevel + 1) * 2) ^ (2.5 + 20));
  }

  public static getEnhancementBonus(currentLevel: number): number {
    switch (currentLevel) {
      case 0:
        return 0;
      case 1:
        return 1;
      case 2:
        return 2;
      case 3:
        return 3;
      case 4:
        return 4;
      case 5:
        return 6;
      case 6:
        return 8;
      case 7:
        return 11;
      case 8:
        return 14;
      case 9:
        return 17;
      case 10:
        return 18;
      case 11:
        return 19;
      case 12:
        return 20;
      case 13:
        return 21;
      case 14:
        return 22;
      case 15:
        return 23;
      case 16:
        return 24;
      case 17:
        return 25;
    }
    return 0;
  }

  public getSkillPower(sheet: CharacterSheet): number {
    return 1 + sheet.smithingLevel * 0.2;
  }

  public getFarmString(completionCount: number, itemTarget: string): string {
    const itemName = ItemTable[parseInt(itemTarget ?? '0')].name;

    return `You smithed ${completionCount} ${itemName}.`;
  }
}

type TimerLengthSelectorProps = { setLength: (length: number) => void };

export default function TimerLengthSelector({
  setLength,
}: TimerLengthSelectorProps) {
  return (
    <div>
      <select
        onChange={(e) => {
          e.preventDefault();
          setLength(parseInt(e.target.value));
        }}
      >
        <option value={5 * 60}>5 minutes</option>
        {process.env?.REACT_APP_ENV_VAR?.trim() === 'development' ? (
          <option value={2}>seconds</option>
        ) : null}
        <option value={10 * 60}>10 minutes</option>
        <option value={15 * 60}>15 minutes</option>
        <option value={20 * 60}>20 minutes</option>
        <option value={25 * 60}>25 minutes</option>
        <option value={30 * 60}>30 minutes</option>
        <option value={35 * 60}>35 minutes</option>
      </select>
    </div>
  );
}

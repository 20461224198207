import { Route, Routes, useNavigate } from 'react-router-dom';
import Navbar from './Navbar.react';
import styled from 'styled-components';
import ActivityPage from './ActivityPage.react';
import CharacterSheetPage from './CharacterSheetPage.react';
import TimerLog, { ITimer } from './TimerLog.react';
import { useEffect, useState } from 'react';
import {
  CHARSHEET_VERSION,
  saveSheet,
  updateInventoryComponents,
} from '../db/CharacterSheetStorage';
import { IInvItem } from '../db/ItemTable';
import {
  CharacterSheet,
  characterSheetDefault,
} from './util/types/CharacterSheetType';
import { gql, useMutation, useQuery } from '@apollo/client';
import { GET_ACCOUNT_DATA } from './util/AccountQueries';
import { CharacterSheetContext } from './util/CharacterSheetContext';

type UserPageProps = {
  accountId: string | null;
  logout: () => void;
};

const ContentContainer = styled.div`
  display: flex;
  flex-direction: row;
  width: 100vw;
`;

const SAVE_CHARACTER_SHEET = gql`
  mutation SaveCharacterSheet($newCharacterSheet: String!, $userId: String!) {
    saveCharacterSheet(newCharacterSheet: $newCharacterSheet, userId: $userId) {
      id
    }
  }
`;

function getTotalSecondsFocused(timers: Array<ITimer>): number {
  const todayStartTime = new Date().setHours(0, 0, 0);
  const todaysTimers = timers.filter(
    (timer) => Date.parse(timer.startTime) >= todayStartTime,
  );
  return todaysTimers.reduce(
    (accumulator, timer) => accumulator + timer.durationInSeconds,
    0,
  );
}

export default function User({ accountId, logout }: UserPageProps) {
  const [secondsFocused, setSecondsFocused] = useState(0);
  const [saveCharacterSheet] = useMutation(SAVE_CHARACTER_SHEET);
  const navigate = useNavigate();
  if (accountId === null) {
    navigate('/');
  }
  const [characterSheet, setCharacterSheet] = useState<CharacterSheet>(
    characterSheetDefault,
  );
  const [timers, setTimers] = useState(Array<ITimer>);

  const { data: getAccountDataResponse } = useQuery(GET_ACCOUNT_DATA, {
    variables: { accountId: accountId },
  });
  const accountData = getAccountDataResponse?.getAccountData;

  useEffect(() => {
    if (accountData?.encodedCharacterSheet != null) {
      // set character sheet
      try {
        let sheet = JSON.parse(window.atob(accountData?.encodedCharacterSheet));

        let items = sheet.inventory.items.map(
          (item: IInvItem, index: number) => {
            return { ...item, position: index };
          },
        );
        if (sheet?.charSheetVersion !== CHARSHEET_VERSION) {
          items = updateInventoryComponents(items);
          sheet.charSheetVersion = CHARSHEET_VERSION;
        }

        const characterSheet = {
          str: sheet?.str ?? 1,
          dex: sheet?.dex ?? 1,
          int: sheet?.int ?? 1,
          luk: sheet?.luk ?? 1,
          charSheetVersion: sheet?.charSheetVersion ?? CHARSHEET_VERSION,
          herbalismLevel: sheet?.herbalismLevel ?? 1,
          herbalismExperience: sheet?.herbalismExperience ?? 0,
          forgingLevel: sheet?.forgingLevel ?? 1,
          forgingExperience: sheet?.forgingExperience ?? 0,
          rockFarmingLevel: sheet?.rockFarmingLevel ?? 1,
          rockFarmingExperience: sheet?.rockFarmingExperience ?? 0,
          smithingLevel: sheet?.smithingLevel ?? 1,
          smithingExperience: sheet?.smithingExperience ?? 0,
          inventory: { items: items },
          gear: { ...sheet.gear },
        };
        storeSheet(characterSheet);
      } catch (e) {
        console.error('hit an errr loading the char sheet');
        console.error(e);
        const characterSheet = characterSheetDefault;
        storeSheet(characterSheet);
      }
      // set timers
      try {
        if (accountData?.timers !== null) {
          setTimers(accountData.timers);
          setSecondsFocused(getTotalSecondsFocused(accountData.timers));
        }
      } catch (e) {
        setTimers([]);
      }
    }
  }, [accountData]);

  const storeSheet = async (newSheet: CharacterSheet) => {
    saveSheet(newSheet);
    setCharacterSheet(newSheet);
  };

  const storeAndSaveSheet = async (newSheet: CharacterSheet) => {
    saveSheet(newSheet);
    setCharacterSheet(newSheet);
    await saveCharacterSheet({
      variables: {
        newCharacterSheet: window.btoa(JSON.stringify(newSheet)),
        userId: accountId,
      },
    });
  };

  const saveNewTimer = (newTimer: ITimer) => {
    setSecondsFocused(secondsFocused + newTimer.durationInSeconds);
    setTimers([...timers, newTimer]);
  };
  return (
    <CharacterSheetContext.Provider
      value={{
        characterSheet: characterSheet,
        setCharacterSheet: storeAndSaveSheet,
      }}
    >
      <ContentContainer>
        <Navbar logout={logout} secondsFocused={secondsFocused} />
        <Routes>
          <Route
            path="activity"
            element={
              <ActivityPage
                accountId={accountId ?? ''}
                characterSheet={characterSheet}
                storeSheet={storeSheet}
                addTimerToArray={saveNewTimer}
                tags={accountData?.tags}
              />
            }
          />
          <Route path="characterSheet" element={<CharacterSheetPage />} />
          <Route path="timerLog" element={<TimerLog timers={timers} />} />
        </Routes>
      </ContentContainer>
    </CharacterSheetContext.Provider>
  );
}

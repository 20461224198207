import { ITag } from './util/types/TimerType';

type TagSelectorProps = {
  tags: Array<ITag>;
  setSelectedTag: (tag: string) => void;
  selectedTag: string;
};

export default function TagSelector({
  tags,
  setSelectedTag,
  selectedTag,
}: TagSelectorProps) {
  return (
    <select
      onChange={(e) => {
        e.preventDefault();
        setSelectedTag(e.target.value);
      }}
      value={selectedTag}
    >
      {tags.map((tag: ITag) => (
        <option value={tag.value} key={tag.id}>
          {tag.value}
        </option>
      ))}
    </select>
  );
}

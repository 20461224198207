import { useEffect, useState } from 'react';

export type ITimer = {
  id: string;
  startTime: string;
  durationInSeconds: number;
  tag: {
    id: string;
    value: string;
  };
};

type TimerLogProps = {
  timers: Array<ITimer>;
};

export default function TimerLog({ timers }: TimerLogProps) {
  const [totalActivity, setTotalActivity] = useState(0);

  const calculateTodaysActivity = (timers: Array<ITimer>): number => {
    const today = new Date();
    today.setHours(0);
    today.setMinutes(0);
    today.setSeconds(0);

    const todayAsEpoch = today.getTime();

    if (timers != null && timers.length > 0) {
      const totalSeconds = timers
        .filter((timer) => {
          const startTimeAsEpoch = Date.parse(timer.startTime);
          if (startTimeAsEpoch > todayAsEpoch) {
            return true;
          }
          return false;
        })
        .map((timer: ITimer): number => timer.durationInSeconds)
        .reduce((prev: number, current: number): number => prev + current, 0);
      return Math.trunc(totalSeconds / 60);
    }
    return 0;
  };

  useEffect(() => {
    setTotalActivity(calculateTodaysActivity(timers));
  }, [timers]);

  const prettyPrintDuration = (duration: number): string => {
    let seconds: string | number = duration % 60;
    let minutes: string | number = Math.trunc(duration / 60);
    if (seconds < 10) {
      seconds = '0' + seconds;
    }
    if (minutes < 10) {
      minutes = '0' + minutes;
    }

    return minutes + ':' + seconds;
  };

  const prettyPrintStart = (time: Date): string => {
    const year = time.getFullYear();
    let month: string | number = time.getMonth() + 1;
    let date: string | number = time.getDate();
    let hours: string | number = time.getHours();
    let minutes: string | number = time.getMinutes();
    let ampm = 'AM';
    if (hours > 12) {
      hours = hours - 12;
      ampm = 'PM';
    } else if (hours < 10) {
      hours = '0' + hours;
    }
    if (minutes < 10) {
      minutes = '0' + minutes;
    }
    if (date < 10) {
      date = '0' + date;
    }
    if (month < 10) {
      month = '0' + month;
    }
    return (
      year + '/' + month + '/' + date + ' ' + hours + ':' + minutes + ' ' + ampm
    );
  };

  return (
    <div>
      <div>You have been focused for {totalActivity} minutes today</div>
      <div
        style={{
          width: 500,
          height: 500,
          overflowY: 'scroll',
          border: '2px solid white',
          padding: '5px',
        }}
      >
        {timers?.length > 0 &&
          Array.from(timers)
            .reverse()
            .map((timer) => {
              const timerDate = new Date(timer.startTime);
              return (
                <p key={timer.id}>
                  Start Time: {prettyPrintStart(timerDate)} Duration:{' '}
                  {prettyPrintDuration(timer.durationInSeconds)} Tag:{' '}
                  {timer.tag.value}
                </p>
              );
            })}
      </div>
    </div>
  );
}

import { useState } from 'react';

type TagAdderProps = {
  addTag: (newTag: string) => void;
  cancelAdd: () => void;
};
export default function TagAdder({ addTag, cancelAdd }: TagAdderProps) {
  const [newTag, setNewTag] = useState('');
  return (
    <>
      <input
        type="text"
        onChange={(e) => setNewTag(e.target.value)}
        value={newTag}
      ></input>
      <button
        onClick={() => {
          addTag(newTag);
          setNewTag('');
        }}
      >
        Save
      </button>
      <button
        onClick={() => {
          cancelAdd();
        }}
      >
        Cancel
      </button>
    </>
  );
}
